import React from "react";
import {useSwipeable} from "react-swipeable";
import {
    CarouselContainer,
    CarouselSlot,
    PREV,
    NEXT
} from "./components";

const getOrder = ({index, pos, numItems}) => {
    return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};
const initialState = {pos: 0, sliding: false, dir: NEXT};

const Carousel = (props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const numItems = React.Children.count(props.children);
    const slide = (dir) => {
        dispatch({type: dir, numItems});
        setTimeout(() => {
            dispatch({type: "stopSliding"});
        }, 50);
    };

    const slideTo = (num) => {
        dispatch({type: 'goto', num});
        setTimeout(() => {
            dispatch({type: "stopSliding"});
        }, 50);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => slide(NEXT),
        onSwipedRight: () => slide(PREV),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div {...handlers} onKeyDown={(e) => {
            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                if (e.key === 'ArrowLeft') slide(PREV)
                else slide(NEXT)
                e.preventDefault();
            }
            if (e.key === 'a' || e.key === 'A') {
                slideTo(0);
            }
            if (e.key === 'b' || e.key === 'B') {
                slideTo(1);
            }
            if (e.key === 'c' || e.key === 'C') {
                slideTo(2);
            }
            if (e.key === 'd' || e.key === 'D') {
                slideTo(3);
            }
            if (e.key === 'e' || e.key === 'E') {
                slideTo(4);
            }
            if (e.key === 'f' || e.key === 'F') {
                slideTo(5);
            }
            if (e.key === 'g' || e.key === 'G') {
                slideTo(6);
            }
            if (e.key === 'h' || e.key === 'H') {
                slideTo(7);
            }
            if (e.key === 'i' || e.key === 'I') {
                slideTo(8);
            }
            if (e.key === 'j' || e.key === 'J') {
                slideTo(9);
            }
            if (e.key === 'k' || e.key === 'K') {
                slideTo(10);
            }
            if (e.key === 'l' || e.key === 'L') {
                slideTo(11);
            }
            if (e.key === 'm' || e.key === 'M') {
                slideTo(12);
            }
            if (e.key === 'n' || e.key === 'N') {
                slideTo(13);
            }
            if (e.key === 'o' || e.key === 'O') {
                slideTo(14);
            }
            if (e.key === 'p' || e.key === 'P') {
                slideTo(15);
            }
            if (e.key === 'q' || e.key === 'Q') {
                slideTo(16);
            }
            if (e.key === 'r' || e.key === 'R') {
                slideTo(17);
            }
            if (e.key === 's' || e.key === 'S') {
                slideTo(18);
            }
            if (e.key === 't' || e.key === 'T') {
                slideTo(19);
            }
            if (e.key === 'u' || e.key === 'U') {
                slideTo(20);
            }
            if (e.key === 'v' || e.key === 'V') {
                slideTo(21);
            }
            if (e.key === 'w' || e.key === 'W') {
                slideTo(22);
            }
            if (e.key === 'x' || e.key === 'X') {
                slideTo(23);
            }
            if (e.key === 'y' || e.key === 'Y') {
                slideTo(24);
            }
            if (e.key === 'z' || e.key === 'Z') {
                slideTo(25);
            }
        }} style={{overflow: 'hidden', outline: 'none'}} tabIndex={0}>
            <CarouselContainer dir={state.dir} sliding={state.sliding}>
                {React.Children.map(props.children, (child, index) => (
                    <CarouselSlot
                        key={index}
                        order={getOrder({index: index, pos: state.pos, numItems})}
                    >
                        {child}
                    </CarouselSlot>
                ))}
            </CarouselContainer>
        </div>
    );
};

function reducer(state, payload) {
    switch (payload.type) {
        case 'goto': {
            if (state.sliding) return state;
            return {
                ...state,
                dir: PREV,
                sliding: true,
                pos: payload.num
            };
        }
        case "reset":
            return initialState;
        case PREV:
            if (state.sliding) return state;
            return {
                ...state,
                dir: PREV,
                sliding: true,
                pos: state.pos === 0 ? payload.numItems - 1 : state.pos - 1
            };
        case NEXT:
            if (state.sliding) return state;
            return {
                ...state,
                dir: NEXT,
                sliding: true,
                pos: state.pos === payload.numItems - 1 ? 0 : state.pos + 1
            };
        case "stopSliding":
            return {...state, sliding: false};
        default:
            return state;
    }
}

export default Carousel;
