import './App.css';
import React from 'react';
import words from './words';
import Carousel from "./Carousel";
import Card from "./Card";
import a from './audio/a.m4a';
import b from './audio/b.m4a';
import c from './audio/c.m4a';
import d from './audio/dee.m4a';
import e from './audio/e.m4a';
import f from './audio/f.m4a';
import g from './audio/g.m4a';
import h from './audio/h.m4a';
import i from './audio/i.m4a';
import j from './audio/j.m4a';
import k from './audio/k.m4a';
import l from './audio/l.m4a';
import m from './audio/m.m4a';
import n from './audio/n.m4a';
import o from './audio/o.m4a';
import p from './audio/p.m4a';
import q from './audio/q.m4a';
import r from './audio/r.m4a';
import s from './audio/s.m4a';
import t from './audio/t.m4a';
import u from './audio/u.m4a';
import v from './audio/v.m4a';
import w from './audio/w.m4a';
import x from './audio/x.m4a';
import y from './audio/y.m4a';
import z from './audio/z.m4a';
import zebra from './audio/zebra.m4a';
import zip from './audio/zip.m4a';
import antelope from './audio/antelope.m4a';
import apple from './audio/apple.m4a';
import baby from './audio/baby.m4a';
import bus from './audio/bus.m4a';
import cat from './audio/cat.m4a';
import clever from './audio/clever.m4a';
import duck from './audio/duck.m4a';
import dog from './audio/dog.m4a';
import elephant from './audio/elephant.m4a';
import envelope from './audio/envelope.m4a';
import fox from './audio/fox.m4a';
import friends from './audio/friends.m4a';
import golfer from './audio/golfer.m4a';
import girl from './audio/girl.m4a';
import hotel from './audio/hotel.m4a';
import helicopter from './audio/helicopter.m4a';
import ice from './audio/ice.m4a';
import igloo from './audio/igloo.m4a';
import jeep from './audio/jeep.m4a';
import juice from './audio/juice.m4a';
import kite from './audio/kite.m4a';
import kangaroo from './audio/kangaroo.m4a';
import lemon from './audio/lemon.m4a';
import lego from './audio/lego.m4a';
import mother from './audio/mother.m4a';
import monkey from './audio/monkey.m4a';
import noodles from './audio/noodles.m4a';
import night from './audio/night.m4a';
import orange from './audio/orange.m4a';
import owl from './audio/owl.m4a';
import pig from './audio/pig.m4a';
import panda from './audio/panda.m4a';
import queen from './audio/queen.m4a';
import quick from './audio/quick.m4a';
import rabbit from './audio/rabbit.m4a';
import rocket from './audio/rocket.m4a';
import sheep from './audio/sheep.m4a';
import ship from './audio/ship.m4a';
import tiger from './audio/tiger.m4a';
import tree from './audio/tree.m4a';
import umbrella from './audio/umbrella.m4a';
import unicorn from './audio/unicorn.m4a';
import violin from './audio/violin.m4a';
import volcano from './audio/volcano.m4a';
import wall from './audio/wall.m4a';
import wet from './audio/wet.m4a';
import xray from './audio/xray.m4a';
import xylophone from './audio/xylophone.m4a';
import yacht from './audio/yacht.m4a';
import yawn from './audio/yawn.m4a';

const App = () =>
    <div>
        <div>
            <audio id='a'>
                <source preload="auto"
                        src={a}/>
            </audio>
            <audio id='b'>
                <source preload="auto"
                        src={b}/>
            </audio>
            <audio id='c'>
                <source preload="auto"
                        src={c}/>
            </audio>
            <audio id='d'>
                <source preload="auto"
                        src={d}/>
            </audio>
            <audio id='e'>
                <source preload="auto"
                        src={e}/>
            </audio>
            <audio id='f'>
                <source preload="auto"
                        src={f}/>
            </audio>
            <audio id='g'>
                <source preload="auto"
                        src={g}/>
            </audio>
            <audio id='h'>
                <source preload="auto"
                        src={h}/>
            </audio>
            <audio id='i'>
                <source preload="auto"
                        src={i}/>
            </audio>
            <audio id='j'>
                <source preload="auto"
                        src={j}/>
            </audio>
            <audio id='k'>
                <source preload="auto"
                        src={k}/>
            </audio>
            <audio id='l'>
                <source preload="auto"
                        src={l}/>
            </audio>
            <audio id='m'>
                <source preload="auto"
                        src={m}/>
            </audio>
            <audio id='n'>
                <source preload="auto"
                        src={n}/>
            </audio>
            <audio id='o'>
                <source preload="auto"
                        src={o}/>
            </audio>
            <audio id='p'>
                <source preload="auto"
                        src={p}/>
            </audio>
            <audio id='q'>
                <source preload="auto"
                        src={q}/>
            </audio>
            <audio id='r'>
                <source preload="auto"
                        src={r}/>
            </audio>
            <audio id='s'>
                <source preload="auto"
                        src={s}/>
            </audio>
            <audio id='t'>
                <source preload="auto"
                        src={t}/>
            </audio>
            <audio id='u'>
                <source preload="auto"
                        src={u}/>
            </audio>
            <audio id='v'>
                <source preload="auto"
                        src={v}/>
            </audio>
            <audio id='w'>
                <source preload="auto"
                        src={w}/>
            </audio>
            <audio id='x'>
                <source preload="auto"
                        src={x}/>
            </audio>
            <audio id='y'>
                <source preload="auto"
                        src={y}/>
            </audio>
            <audio id='z'>
                <source preload="auto"
                        src={z}/>
            </audio>
            <audio id='zebra'>
                <source preload="auto"
                        src={zebra}/>
            </audio>
            <audio id='zip'>
                <source preload="auto"
                        src={zip}/>
            </audio>
            <audio id='antelope'>
                <source preload="auto"
                        src={antelope}/>
            </audio>
            <audio id='apple'>
                <source preload="auto"
                        src={apple}/>
            </audio>
            <audio id='baby'>
                <source preload="auto"
                        src={baby}/>
            </audio>
            <audio id='bus'>
                <source preload="auto"
                        src={bus}/>
            </audio>
            <audio id='cat'>
                <source preload="auto"
                        src={cat}/>
            </audio>
            <audio id='clever'>
                <source preload="auto"
                        src={clever}/>
            </audio>
            <audio id='duck'>
                <source preload="auto"
                        src={duck}/>
            </audio>
            <audio id='dog'>
                <source preload="auto"
                        src={dog}/>
            </audio>
            <audio id='elephant'>
                <source preload="auto"
                        src={elephant}/>
            </audio>
            <audio id='envelope'>
                <source preload="auto"
                        src={envelope}/>
            </audio>
            <audio id='fox'>
                <source preload="auto"
                        src={fox}/>
            </audio>
            <audio id='friends'>
                <source preload="auto"
                        src={friends}/>
            </audio>
            <audio id='golfer'>
                <source preload="auto"
                        src={golfer}/>
            </audio>
            <audio id='girl'>
                <source preload="auto"
                        src={girl}/>
            </audio>
            <audio id='hotel'>
                <source preload="auto"
                        src={hotel}/>
            </audio>
            <audio id='helicopter'>
                <source preload="auto"
                        src={helicopter}/>
            </audio>
            <audio id='ice'>
                <source preload="auto"
                        src={ice}/>
            </audio>
            <audio id='igloo'>
                <source preload="auto"
                        src={igloo}/>
            </audio>
            <audio id='jeep'>
                <source preload="auto"
                        src={jeep}/>
            </audio>
            <audio id='juice'>
                <source preload="auto"
                        src={juice}/>
            </audio>
            <audio id='kite'>
                <source preload="auto"
                        src={kite}/>
            </audio>
            <audio id='kangaroo'>
                <source preload="auto"
                        src={kangaroo}/>
            </audio>
            <audio id='lemon'>
                <source preload="auto"
                        src={lemon}/>
            </audio>
            <audio id='lego'>
                <source preload="auto"
                        src={lego}/>
            </audio>
            <audio id='mother'>
                <source preload="auto"
                        src={mother}/>
            </audio>
            <audio id='monkey'>
                <source preload="auto"
                        src={monkey}/>
            </audio>
            <audio id='noodles'>
                <source preload="auto"
                        src={noodles}/>
            </audio>
            <audio id='night'>
                <source preload="auto"
                        src={night}/>
            </audio>
            <audio id='orange'>
                <source preload="auto"
                        src={orange}/>
            </audio>
            <audio id='owl'>
                <source preload="auto"
                        src={owl}/>
            </audio>
            <audio id='pig'>
                <source preload="auto"
                        src={pig}/>
            </audio>
            <audio id='panda'>
                <source preload="auto"
                        src={panda}/>
            </audio>
            <audio id='queen'>
                <source preload="auto"
                        src={queen}/>
            </audio>
            <audio id='quick'>
                <source preload="auto"
                        src={quick}/>
            </audio>
            <audio id='rabbit'>
                <source preload="auto"
                        src={rabbit}/>
            </audio>
            <audio id='rocket'>
                <source preload="auto"
                        src={rocket}/>
            </audio>
            <audio id='sheep'>
                <source preload="auto"
                        src={sheep}/>
            </audio>
            <audio id='ship'>
                <source preload="auto"
                        src={ship}/>
            </audio>
            <audio id='tiger'>
                <source preload="auto"
                        src={tiger}/>
            </audio>
            <audio id='tree'>
                <source preload="auto"
                        src={tree}/>
            </audio>
            <audio id='umbrella'>
                <source preload="auto"
                        src={umbrella}/>
            </audio>
            <audio id='unicorn'>
                <source preload="auto"
                        src={unicorn}/>
            </audio>
            <audio id='violin'>
                <source preload="auto"
                        src={violin}/>
            </audio>
            <audio id='volcano'>
                <source preload="auto"
                        src={volcano}/>
            </audio>
            <audio id='wall'>
                <source preload="auto"
                        src={wall}/>
            </audio>
            <audio id='wet'>
                <source preload="auto"
                        src={wet}/>
            </audio>
            <audio id='xray'>
                <source preload="auto"
                        src={xray}/>
            </audio>
            <audio id='xylophone'>
                <source preload="auto"
                        src={xylophone}/>
            </audio>
            <audio id='yacht'>
                <source preload="auto"
                        src={yacht}/>
            </audio>
            <audio id='yawn'>
                <source preload="auto"
                        src={yawn}/>
            </audio>
        </div>
        <Carousel>
            {words.map((lettersWords, i) =>
                <Card key={i} lettersWords={lettersWords}/>)}
        </Carousel>
    </div>

export default App;
