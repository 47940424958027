import React from "react";

function play(id) {
    document.getElementById(id).play()
}

function Card({lettersWords}) {
    const uppercase = lettersWords[0].charAt(0).toUpperCase();
    const lowercase = uppercase.toLowerCase();
    return <div className="card">
        <div className={'letters'}><span onClick={() => play(lowercase)}>{uppercase}</span><span
            onClick={() => play(lowercase)}>{lowercase}</span></div>
        <div className='words'>
            {lettersWords.map(w => <div className='word' key={w}>
                <img src={'images/' + w.toLowerCase() + '.png'} max-width={'100%'}
                     onClick={() => play(w.toLowerCase())}/>
                <span>{w}</span>
            </div>)}
        </div>
    </div>
}

export default Card;