const words = [
    ['Zebra', 'Zip'],
    ['Antelope', 'Apple'],
    ['Baby', 'Bus'],
    ['Cat', 'Clever'],
    ['Duck', 'Dog'],
    ['Elephant', "Envelope"],
    ['Fox', 'Friends'],
    ['Golfer', 'Girl'],
    ['Hotel', 'Helicopter'],
    ['Ice', 'Igloo'],
    ['Jeep', 'Juice'],
    ['Kite', 'Kangaroo'],
    ['Lemon', 'Lego'],
    ['Mother', 'Monkey'],
    ['Noodles', 'Night'],
    ['Orange', 'Owl'],
    ['Pig', 'Panda'],
    ['Queen', 'Quick'],
    ['Rabbit', 'Rocket'],
    ['Sheep', 'Ship'],
    ['Tiger', 'Tree'],
    ['Umbrella', 'Unicorn'],
    ['Violin', 'Volcano'],
    ['Wall', 'Wet'],
    ['Xray', 'Xylophone'],
    ['Yacht', 'Yawn']
]

export default words;